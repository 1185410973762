@use "sass:math";

/* ==========================================================================
|  RESET
========================================================================== */
*, *:before, *:after { box-sizing: border-box; }

 *:focus{outline: none!important;}

/* ==========================================================================
|  FONT
========================================================================== */
/*
@import url(https://fonts.googleapis.com/css?family=Oswald:200,400);
@import url(https://fonts.googleapis.com/css?family=Raleway:300,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,400,600);
*/
.oswald{font-family: 'Oswald', sans-serif;}
.raleway{font-family: 'Open Sans', sans-serif;}
.awesome{font-family: 'FontAwesome', serif; font-weight: normal;}
.open{font-family: 'Open Sans', sans-serif;}

/* ==========================================================================
|  FONCTIONS
========================================================================== */

@function calculateRem($size) {
	$remSize: math.div($size, 10px);
	@return $remSize * 1rem;
}

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
	line-height: calculateRem($size+10);
}

@mixin radius($radius) {
	border-radius: $radius;
}

/* ==========================================================================
|  BOUCLES
========================================================================== */

// @for $i from 1 through 6 {
//    #block-system-main article:nth-child(#{$i}) {
//        background: darken($or, 5% * $i);
//    }
// }

/* ==========================================================================
|  TRANSITION
========================================================================== */

.transition{transition: all 300ms linear 0ms;}
.transition_rapide{transition: all 150ms linear 0ms;}
.transition_delay{transition: all 300ms linear 15ms;}

.apparition{animation-name: fadeIn; animation-duration: 2s; animation-fill-mode: both; }

@-webkit-keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}
@keyframes fadeIn {
	0% {opacity: 0;}
	100% {opacity: 1;}
}

/* ==========================================================================
|  BOUTON
========================================================================== */

a{color: $orange; outline:none!important;}

.page-node-75483 a.lexicon-term{color: #fff; }
a.lexicon-term{color: $orange; border-bottom: 1px dotted $orange;}

input[type="submit"],
.btn{background: $orange; @include font-size(16px); padding: 5px 25px; @extend .transition; font-family: 'Open Sans', sans-serif; font-weight: 500!important; color: #FFF!important; text-transform: uppercase; text-decoration: none; }
input[type="submit"]:hover,
.btn:hover{background: $gris;}

.group-header-info-right,
table{
	.file{
		.file-size,
		.file-icon{display: none;}
		a{@extend .btn; /* width: 100%; display: block; */ font-family: 'Open Sans Condensed', sans-serif;
			&:before{@extend .awesome; content: "\f019"; color: #FFF; font-size: 20px; margin: 0 10px; display: inline-block; vertical-align: middle;}
		}
	}
}

/* ==========================================================================
|  TITRES
========================================================================== */

h1, .h1{@include font-size(60px); color: $orange; font-family: 'Oswald', sans-serif; font-weight: 400; text-transform: uppercase; margin: 20px 0;}
h2, .h2{@include font-size(50px); color: $couleur_base; font-family: 'Oswald', sans-serif; font-weight: 200; text-transform: uppercase; position: relative; text-align: center; margin-bottom: 30px;
	span{padding: 0 30px; background: #FFF; z-index: 2; position: relative;}
	&:after{position: absolute; content: ""; height: 2px; background: $couleur_base; display: block; width: 100%; top: 32px;}
}
h3, .h3{@include font-size(24px); color: #333; font-family: 'Open Sans', sans-serif;  font-weight: 700; text-transform: uppercase;}
h4, .h4{@include font-size(18px); text-transform: uppercase; color: $orange; font-weight: 700;}

.fieldset-legend{@include font-size(24px); color: #333; font-family: 'Open Sans', sans-serif;  font-weight: 700; text-transform: uppercase;}

.gradiant{
	background: rgba(255,255,255,0);
	background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 20%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,0) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(20%, rgba(255,255,255,0.8)), color-stop(80%, rgba(255,255,255,0.8)), color-stop(100%, rgba(255,255,255,0)));
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 20%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,0) 100%);
	background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 20%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,0) 100%);
	background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 20%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,0) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 20%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );}

.gradiant_noir{
	background: rgba(51,51,51,0.6);
	/*background: rgba(51,51,51,0);
background: -moz-linear-gradient(left, rgba(51,51,51,0) 0%, rgba(51,51,51,0) 5%, rgba(51,51,51,0.7) 20%, rgba(51,51,51,0.7) 80%, rgba(51,51,51,0) 95%, rgba(51,51,51,0) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(51,51,51,0)), color-stop(5%, rgba(51,51,51,0)), color-stop(20%, rgba(51,51,51,0.7)), color-stop(80%, rgba(51,51,51,0.7)), color-stop(95%, rgba(51,51,51,0)), color-stop(100%, rgba(51,51,51,0)));
background: -webkit-linear-gradient(left, rgba(51,51,51,0) 0%, rgba(51,51,51,0) 5%, rgba(51,51,51,0.7) 20%, rgba(51,51,51,0.7) 80%, rgba(51,51,51,0) 95%, rgba(51,51,51,0) 100%);
background: -o-linear-gradient(left, rgba(51,51,51,0) 0%, rgba(51,51,51,0) 5%, rgba(51,51,51,0.7) 20%, rgba(51,51,51,0.7) 80%, rgba(51,51,51,0) 95%, rgba(51,51,51,0) 100%);
background: -ms-linear-gradient(left, rgba(51,51,51,0) 0%, rgba(51,51,51,0) 5%, rgba(51,51,51,0.7) 20%, rgba(51,51,51,0.7) 80%, rgba(51,51,51,0) 95%, rgba(51,51,51,0) 100%);
background: linear-gradient(to right, rgba(51,51,51,0) 0%, rgba(51,51,51,0) 5%, rgba(51,51,51,0.7) 20%, rgba(51,51,51,0.7) 80%, rgba(51,51,51,0) 95%, rgba(51,51,51,0) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333333', endColorstr='#333333', GradientType=1 );*/}

/* ==========================================================================
|  SLIDER
========================================================================== */

.jcarousel-container{position: relative; max-width: 100%; padding: 0!important; height: auto!important;

	.jcarousel-prev{left: 0; position: absolute; top: 50%; transform:translateY(-50%); background: $arrow_left; width: 22px; height: 60px;}
	.jcarousel-next{right: 0; position: absolute; top: 50%; transform:translateY(-50%); background: $arrow_right; width: 22px; height: 60px;}

	.jcarousel-clip{max-width: 100%;}
	.jcarousel-item{border: none; height: auto!important; }
}

/* ==========================================================================
|  Class global
========================================================================== */

body{font-family: 'Open Sans', sans-serif; font-weight: 500; @include font-size(16px);}

.node-unpublished{ background-color: #fff; }

.fixModal {
    padding-right: 0 !important;
    margin-right: 0 !important;
}

.inline-block{
    display: flex;
    align-items: center;
    justify-content: center;
}

.hidden{ display: none; }

.line-through{ text-decoration: line-through; }

ul{margin: 0; padding: 0;}
.row{margin: 0;
	[class*=col]{padding: 0}
}

p{@include font-size(16px);}

img.views-label-tooltip-icon { display: initial; }

.element-invisible{display: none!important;}
.uppercase{text-transform: uppercase;}

.bloc-cadre{border-top:1px solid #d8d8d8; border-left:1px solid #d8d8d8; border-right:1px solid #d8d8d8; border-bottom:7px solid $couleur_base;  padding: 20px;
	ul{padding-left: 20px;}
}

.block{padding: 0}

.field-type-taxonomy-term-reference .field-item:after{display: none;}
#messages-console{position: fixed; z-index: 10; max-width: 350px;}

input.form-text, select{min-height: 36px; height: 36px; padding: 0 15px;}
textarea.form-textarea{min-height: 36px; padding: 0 15px;}
input.form-text, textarea.form-textarea, select{border:2px solid #CCC;}
.chosen-single{ min-height: 36px; line-height: 36px; }

.carre{
	&:before{content: ""; display: inline-block; position: relative; vertical-align: middle; width: 5px; height: 5px; background: $orange; margin-right: 12px; }
}

.qtip-bootstrap{ border: 1px solid rgba(1,140,146,0.8); }

.view-liste-des-lots-detaillee{
	.view-filters{
		.views-widget-filter-field_etat_lot_value{
			.views-widget{ display: inline-block;
				.form-item-field-etat-lot-value{
					.form-type-bef-checkbox{ display: inline-block; }
				}
			}
		}
	}
}

.views-table,
.sticky-table{ width: 100%;  text-align: center; border-bottom: 7px solid $couleur_base; margin: 20px 0;
	caption{text-align: left; @include font-size(24px); font-family: 'Oswald', sans-serif; font-weight: 400; margin-bottom: 20px;}
	tr{
		th{padding: 1rem 0.5rem; height: 1rem; background:#f6f6f6; text-align: center; color: #333; font-weight: 500; @include font-size(16px);
			a{color: #333; font-weight: 500;}
		}

		td{ padding: 1rem 0.5rem; height: 1rem; color: #999; border: 2px solid #f7f7f7; @include font-size(16px); position: relative;}

		td.views-field-field-plans-lot{ width: 120px; border-left-color: $orange; border-right-color: $orange;
            /* .file{position: relative; left: -17px; */
                a{ font-weight: 800!important; color: #018c92!important; white-space: nowrap;
                    .views-field-tooltip-icon{ margin-left: 10px; }
                }
            /* } */
        }
        td.views-field-views-conditional-2{ width: 140px;
            a{ font-weight: 800!important; color: #018c92!important; white-space: nowrap;
                .views-field-tooltip-icon{ margin-left: 10px; }
            }
        }

		td.views-field-nothing-3{ /* max-width: 130px; */
			a{white-space: normal; /* border-radius:0;  border: none; */ @include font-size(12px); line-height: 13px}
		}
		td.active{background: inherit;}
		td.views-field-field-surface-lot,
		td.views-field-field-cogedim-lot-surface{width: 90px;}
		td.views-field-field-surf-planch-lot{width: 145px;}
        td.views-field-field-notice-lot span.file{
            display: block;
            margin: 0 auto;
        }
		td.views-field-field-prix-lot,
		td.views-field-views-conditional-3,
		td.views-field-field-cogedim-lot-surface,
		td.views-field-field-cogedim-lot-mntht,
		td.views-field-field-cogedim-lot-mntttc{white-space: nowrap;}

	}
	.Vendu{background: #feeeee}
	.vendu{background: #feeeee}

	.Option{background: #DFECF6}
	.option{background: #DFECF6}

	.Vendu .views-field-field-prix-lot, .Vendu .views-field-views-conditional-3{text-indent: -9999px!important;}
	.vendu .views-field-field-prix-lot, .vendu .views-field-views-conditional-3{text-indent: -9999px!important;}

	.file a{padding: 0; background: none!important; color: #999!important; @include font-size(16px); text-transform: none;
		&:before{display: none;}
		&:after{content: ""; width: 28px; height: 28px; background: url(../images/picto_download.png) no-repeat; position: absolute; margin-left: 8px;}
	}
}

/* ==========================================================================
|  BASE
========================================================================== */
.header_site{position: fixed; width: 100%; top: 0; z-index: 20;}

body{position: relative;
	#header-top{background: $couleur_base; color: #FFF; border:none; height: 40px; min-height: 40px;
		p{line-height: 36px;}
		.rappel{margin-top: 4px;}
		.lnk-partenaires{margin-top: 4px; padding: 3px; margin-right: 3px; color: #fff;}
		#header-top-left{
			ul{padding-left: 20px;}
		}
	}

	#header{/* height: 127px; */ min-height: 0; text-align: left; background: #FFF; padding:0;  @extend .transition;
		#logo{text-align: left; position: relative; z-index: 21; padding: 0; float: left;
			a{
				img{max-height: 130px; width: auto; padding: 20px 0; @extend .transition; }
			}
		}
		#header-inside{position: relative;

		}
		#header-inside:before{position: absolute; right: 0; height: 100%; top: 0; width: 100%; display: block; opacity: 1; @extend .transition; background: $bg_header;}
	}

	#main-navigation{ @extend .transition; height: 56px;
		#main-navigation-inside{@extend .transition; float: right;width: 100%; position: relative; top: 0;
			.region-navigation{display: table; width: 100%;
				.block-tb-megamenu{display: table-cell; width: calc(100% - 50px);
					.tb-megamenu{background: none;}
					.nav-collapse > ul{display: table; width: 100%;
						> li{display: table-cell; /* text-align: center; */ float: none;
							> span,
							> a{color: $couleur_base; @include font-size(16px); font-family: 'Oswald', sans-serif; text-transform: uppercase; font-weight: 400; border: none; background: none; padding: 15px 10px;
								&:hover{color: $orange;}
							}
							a,span{font-family: 'Oswald', sans-serif; }
							&.active{
								> span,
								> a{color: #FFF; background: $orange;}
							}
							&.actu{
								.caret,
								.tb-megamenu-submenu{display: none!important;}
							}

							.dropdown-menu{border-bottom: 7px solid $orange;
								li:first-child{
									a{border:none!important;}
								}
								a{border-top: 1px solid #eee!important;  padding: 10px 20px; white-space: nowrap;
									&:hover{color: $orange;}
								}
								.active > a{background: rgba(229, 78, 75, 0.1); color: #333;}
							}

						}
					}
				}
				#block-search-form{display: table-cell; vertical-align: top; width: 50px; border-left: 1px solid $orange;
					input.form-text{position: absolute; width: 300px; top:56px; right: 0;}
					.form-actions{right: 0; position: relative; top: 5px;
						input.form-submit{width: 50px; height: 56px; display: none;}
						&:after{width: 50px; text-align: center; display: block; top: 0; height: 50px; line-height: 50px; position: relative;}
					}
				}
			}
		}

	}

	@-webkit-keyframes pulse {
	    0% { opacity: 1; filter: alpha(opacity = 100); }
	    70% { opacity: 1; filter: alpha(opacity = 100); }
	    100% { opacity: 0; filter: alpha(opacity = 0); }
	}
	@keyframes pulse {
	    0% { opacity: 1; filter: alpha(opacity = 100); }
	    70% { opacity: 1; filter: alpha(opacity = 100); }
	    100% { opacity: 0; filter: alpha(opacity = 0); }
	}

 	&.node-type-page-d-accueil #fixe li.site span{-webkit-animation: pulse 15s ease-in-out; animation: pulse 15s ease-in-out;}

	#fixe{position: fixed; z-index: 30;  top: 223px; right: 0; width: 70px; @extend .transition;
		.picto_right_middle{margin-top: 100px}
		li{background: rgba(255, 255, 255, 0.8);  color: $orange;  list-style-type: none;  margin-bottom: 3px; position: relative; border: 1px solid #d9d9d9;cursor: pointer;
			i{line-height: 70px; font-size: 36px; text-align: center; display: block; width: 70px; border-right: 5px solid $couleur_base;  @extend .transition;}
			.fa-envelope{font-size: 30px;}
			span{display:none; position: absolute; padding: 22px 20px; background: rgba(255, 255, 255, 0.8); transform:translateY(-50%); top: 50%;  text-transform: uppercase; right: 70px; opacity: 0; @extend .transition; white-space: nowrap;}
			&:hover{
				span{ opacity: 1; display: block;}
			}
		}
		&.active{right: 400px;
			.picto_active i{background: $orange; color: #FFF; }
		}

	}

	#fixe_overlay{position: fixed; z-index: 29; top: 0; right: -400px; width: 400px; height: 100%; background: rgba(0, 0, 0, 0.9); @extend .transition; padding: 30px 20px; color: #FFF;
		&.active{right: 0; overflow: auto;}
		.close-btn{position: absolute; z-index: 10; right: 20px; top: 60px; cursor: pointer; font-size: 30px;}

		.bloc-cadre{background: #FFF; color: #000; margin-top: 30px; }


		h3{color: #FFF; margin-top: 30px; }

		form{
			fieldset{margin: 0;
				.form-item{clear: both; margin-bottom: 5px; margin-top: 0;}
				.webform-component-radios{
					.form-type-radio{float: left; margin-right: 15px; clear: none;}
				}
				textarea{min-height: 150px;}

			}
			.webform-component--vie-privee{
				p,
				small{@include font-size(10px);}
			}


		}

		.overlay_site{
			.btn{position: absolute; transform:translateX(-50%); left: 50%; margin-top: -90px;}
		}

		.overlay_tel p{margin-bottom: 0;}
		.overlay_tel .bloc-cadre{margin-top: 5px;}
	}

	#banner{background: #FFF; margin-top: 223px; overflow: hidden;
		#banner-inside{padding: 0;
			.slide-accueil{
				li{position: relative;
					.contenu-slide{position: absolute; transform:translate(-50%,-50%); left: 50%; top: 50%; text-align: center; width: 100%;
						.slide-titre{@include font-size(60px); color: $orange; font-family: 'Oswald', sans-serif; font-weight: 400; text-transform: uppercase;@extend .gradiant; margin: 20px 0; padding: 20px 40px}
						.slide-body{@include font-size(30px); color: #000; font-family: 'Oswald', sans-serif; font-weight: 400; text-transform: uppercase;
							.btn{ @include font-size(30px); font-family: 'Oswald', sans-serif; }
						}
					}
					img{ max-width: 100%; height: auto; width: 100%; }
				}
				.jcarousel-container{
					.jcarousel-next,
					.jcarousel-prev{display: none;}
				}
				.jcarousel-navigation{position: absolute; bottom: 20px; text-align: center; width: 100%;
					li{display: inline-block; vertical-align: top; float: none;
						a{width: 20px; height: 20px; background: #FFF; border: 2px solid $orange; border-radius:100%; margin: 0 5px; display: block;
							span{display: none;}
						}
						&.active a{background: $orange;}
					}
				}
			}
		}
	}
	#page{background: #FFF;

	}


	&.scroll{
		#header{height: 70px;
			#logo{text-align: left;
				img{max-height: 70px; padding: 10px 0;}
			}
		}
		#main-navigation{ height: 0;
			#main-navigation-inside{width:calc(100% - 170px); float: right; top: -57px; background: none;
				.region-navigation .block-tb-megamenu .nav-collapse > ul > li > a{padding: 15px 10px; @include font-size(14px);}
			}
		}
		#header-inside:before{opacity: 0.2!important}

		// .close-btn{}
	}

	#section_after2{
		.view-lodgim-pour-nous-joindre{max-width: 100%; width: 1140px; margin: 0 auto 40px auto;
			.view-content{
				.views-row{ float: left; width: 50%; color: #888; padding: 10px;
					.node-agence{@extend .bloc-cadre; padding: 0; @extend .open;
						.alerte{ background-color: #ed1c24; color: #fff; padding: 5px 10px;background-image: -webkit-linear-gradient(30deg, #ed1c24 67%, #fff 78%); }
						.group-contenu{padding: 20px; position: relative;
							&:before{background: $picto_contact; background-size:100%;box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);  border: 10px solid #FFF;  content: ""; z-index: 10; width: 80px; height: 80px; border-radius: 100%; display: block; position: absolute; margin-top: -60px; right: 15px; }
							.field-name-title{text-align: center; padding: 0!important;
								h3{ @include font-size(18px); color: #333; margin: 0 0 15px 0; font-weight: normal; }
							}
							.field-name-field-email{
								&:before{content: "\f0e0"; color: $orange; @extend .awesome; display: block; float: left; width: 30px; position: absolute; left: 0;}
							}
							.field-name-field-num-tel{ margin-top: 20px; @extend .oswald; color: $orange;
								&:before{content: "\f095"; color: $orange; @extend .awesome; display: block; float: left; width: 30px; position: absolute; left: 0;}
							}
							.field-name-field-num-portable{@extend .oswald; color: $orange;
								&:before{content: "\f095"; color: $orange; @extend .awesome; display: block; float: left; width: 30px; position: absolute; left: 0;}
							}
							.field-name-field-adresse{height: 80px;
								&:before{content: "\f015"; color: $orange; @extend .awesome; display: block; float: left; width: 30px; position: absolute; left: 0;}
								.country{display: none;}
							}
							.gmapdir{height: 80px; padding-left: 30px; position: relative;
								&:before{content: "\f277"; color: $orange; @extend .awesome; display: block; float: left; width: 30px; position: absolute; left: 0;}
							}
							.country{display: none;}
							.field-name-field-unique{text-transform: uppercase;
								.field-items{display: none}
							}
							.field{padding-left: 30px; position: relative;}
							.field-name-field-info-sup{font-weight: bold; text-transform: uppercase; margin-top: 10px}
							.field-name-field-info-suppl-mentaire{font-weight: bold; @include font-size(25px); text-transform: uppercase; color: $orange; text-align: center; }
						}
					}
				}
				.views-row:last-child{display:block; width: 100%; float: none; clear: both; padding-top: 50px;
					.field-name-field-adresse{height: auto!important;}
					.field{display: inline-block; vertical-align: top; width: 32%; margin: 0!important; text-align: center;

						.field-item{display: inline-block; vertical-align: top;}
						&:before{float: none!important; display: inline-block!important; vertical-align: top!important; position: relative!important;}
					}
					.field-name-title{width: 100%!important;}
					.field-name-field-unique,
					.street-block{margin-top: -20px;}
				}

			}
		}
	}

	#section_after3{background: $couleur_base; color: #FFF; padding: 40px 0;
		#block-block-26{padding-bottom:0;
			.sociaux{display: table; width: 100%;  text-align: center;
				.sociaux_txt{display: table-cell; width: 70%; vertical-align: middle; @include font-size(36px); text-transform: uppercase; font-weight: 300}
				.sociaux_picto{display: table-cell; width: 30%; vertical-align: middle;
					i{font-size: 30px; border: 1px solid #FFF; border-radius: 100%; color: #FFF; width: 50px; height: 50px; margin-right: 15px;line-height: 50px; @extend .transition;}
					a:hover i{background: #FFF; color: $couleur_base;}
				}
			}
		}
	}


	#footer{padding: 40px 0; background: #FFF;
		.footer4{text-align: center;
			p{margin-bottom: 0px; color: $couleur_base; text-transform: uppercase;}
			strong{border: 1px solid #d8d8d8; color: $orange; padding: 10px 20px; font-family: 'Oswald', sans-serif; @include font-size(24px); font-weight: normal; margin: 10px 0; display: inline-block;}
		}
		.menu{
			li{padding: 5px; color: $orange;}
		}

	}
}


/* ==========================================================================
|  ACCUEIL
========================================================================== */

.not-front{
	#page{
		#content-wrapper{background: none; border: none;}
		#main-content{margin-top: 223px; padding: 0;
			.group-banniere{position: relative;
				.field-name-title{@extend .gradiant; text-align: center; position: absolute; top: 50%; left: 50%; width: 100%;  transform:translate(-50%, -50%);}
				img{ width: 100%; }
			}
			.group-contenu{

			}

			.field-name-breadcrumb{width: 1140px; max-width: 100%; margin: auto;
					.breadcrumb{border: none; background: none; padding: 10px 0; margin: 0;
						li:last-child{display: none;}
					}
			}

		}
	}
}

.domain-dev-lodgim-fr.node-type-page-d-accueil #page #main-content .block-system{ position: relative; top: 335px; }

.node-type-page-d-accueil{
	#page{
		#main-content{padding: 0; margin: 0!important;
			#main{padding-bottom: 0;}
			#content-wrapper{border: none; background: none; margin-bottom: 0;
				.region{transform: scaleY(-1); -ms-transform: scaleY(-1); -webkit-transform: scaleY(-1);
					.pour_vendre,
					.pour_habiter,
					.block-system{display: block; transform: scaleY(-1); -ms-transform: scaleY(-1); -webkit-transform: scaleY(-1); }
					.pour_vendre{ top: -400px; position: relative; }
				}

				h1{color: #FFF; @include font-size(20px); text-shadow: 2px 2px 2px #000;}

				.pour_vendre{

					.pour_vendre-body{background: url(../images/terrain-a-vendre.jpg); background-size: cover; position: relative;
						&:before{position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: $couleur_base_opacity; display: block;}
						> .field-item{width: 1140px; max-width: 100%; margin: auto; padding: 30px 15px 30px; color: #FFF; @include font-size(24px); text-align: center; position: relative; color: #EEE;
							a{color: #FFF; font-weight: bold;}

							h3{ background: rgba(1,140,146,0.8);
								background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(20%, rgba(255,255,255,0.8)), color-stop(80%, rgba(1,140,146,0.8)), color-stop(100%, rgba(255,255,255,0)));
								background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(1,140,146,0.8) 10%, rgba(1,140,146,0.8) 90%, rgba(255,255,255,0) 100%);
								background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(1,140,146,0.8) 10%, rgba(1,140,146,0.8) 90%, rgba(255,255,255,0) 100%);
								filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
								text-shadow: 2px 2px 2px #000;
								color: #fff;
								padding: 20px;
							}

						}
					}

				}

				.pour_habiter{position: relative; z-index: 10;
					> .content{padding: 0 15px;}
					.view-lodgim-pour-habiter{box-shadow: 0px 0px 10px 0px rgba(102,102,102,0.5); max-width: 100%; width: 1140px; margin: auto; min-height: 393px; background:#FFF;
						.view-header{position: relative;
							.img_right{position: absolute; right: 0; margin-left: 30px; width: 65%; }
							.content{padding: 20px; width: 35%;
								h3{}
								a{display: inline-block; padding: 2px 10px;}
								a:first-child{margin-right: 5px;}
							}
						}
						.view-content{padding: 0 20px; width: 35%;text-align: center;
							.jcarousel-clip{width: 100%;
								li{position: relative; min-height: 150px;
									.views-field-title{text-transform: uppercase; color: $couleur_base; @extend .gradiant; display: block; width: 100%; position: absolute; top: 50%; transform:translateY(-50%);
										a{color: $couleur_base;}
									}
								}
							}
							.jcarousel-navigation{display: none;}
						}
					}

					.view-lodgim-liste-programmes .slick__slider,
					.view-lodgim-liste-terrains .slick__slider{ /* box-shadow: 0px 0px 10px 0px rgba(102,102,102,.5); */ max-width: 100%; margin: auto;

						.slick-list { overflow: visible;

							.slide__content {

								.terrains { box-shadow: 0px 0px 5px 0px rgba(0,0,0,.75);
								padding: 5px; position: relative;

									span.info_supp{ position: absolute; z-index: 5; bottom: 15px; padding: 10px 3px; background: $orange; color: #fff; @include font-size(13px); white-space: nowrap; border: 1px solid $bleu; }

									span.titre-terrains { position: absolute; top: 8%; text-transform: uppercase; display: block; color: #fff; text-decoration: none; text-shadow: 0 0 5px #000, 0 0 5px #000; background: rgba(51,51,51,.6); width: calc(100% - 10px); text-align: center; }

								}

								&:hover {
										-webkit-transition: all 200ms ease-in;
										-webkit-transform: scale(1.3);
									    -ms-transition: all 200ms ease-in;
									    -ms-transform: scale(1.3);
									    -moz-transition: all 200ms ease-in;
									    -moz-transform: scale(1.3);
									    transition: all 200ms ease-in;
									    transform: scale(1.3);
									    z-index: 10;
									    position: relative;
								}

							}
						}
					}
				}
				.field-name-body{background: $bloc_accueil; background-size: cover; position: relative; top: -100px;
					&:before{position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: $couleur_base_opacity; display: block;}
					> .field-item{width: 1140px; max-width: 100%; margin: auto; padding: 130px 15px 30px; color: #FFF; @include font-size(24px); text-align: center; position: relative; color: #EEE;
						a{color: #FFF; font-weight: bold;}
					}
				}
				.field-name-field-body2{position: relative; top: -20px; width: 100%; max-width: 1140px; margin: auto;}
			}

			/*
			#section_after2,
			#section_after3,
			#footer{ top: -70px; position: relative; }
			*/
		}
	}
}

.node-type-page-d-accueil.domain-www-lodgim-immobilier-fr #banner #banner-inside .slide-accueil li .contenu-slide .slide-titre{@include font-size(42px);}

.page-node-79013 .liste{
	.view-filters{@extend .bloc-cadre; margin-bottom: 40px;
		.views-exposed-widgets{display: table; width: 100%;
			/*
			#edit-field-type-de-bien-tid-wrapper{width: 100%; padding: 10px 0% 0 0%;
				> label{color: $couleur_base; @include font-size(20px); text-transform: uppercase; text-align: center; width: 100%; margin-bottom: 15px;}
				.form-type-select{
					.form-item{width: 18%; display: inline-block; vertical-align: top;
						[type="checkbox"]:not(:checked),
						[type="checkbox"]:checked { position: absolute; left: -9999px;
							+ label{position: relative; padding-right: 10px; cursor: pointer; padding-top: 3px; line-height: 18px; display: inline-block; vertical-align: top; height: 50px;}
							+ label:before{  position: relative; float: left; font-size: 14px; color: $orange; transition: all .2s; @extend .awesome; width: 24px; height: 24px; margin: -4px 10px 20px 0; text-align: center; line-height: 21px; border-radius: 100%;}
						}

						[type="checkbox"]:not(:checked) + label:before {border: 2px solid #999; content: "";}
						[type="checkbox"]:checked + label:before {border: 2px solid $orange; content: "\f00c";}
					}
				}
			}
			*/
			.views-widget-filter-distance{
				.views-widget{width: 90%; display: inline-block; vertical-align: top;
					.form-item{margin: 0; float: none;
						label{float: left; width: 30%; vertical-align: middle; text-align: right; padding-right: 20px; color: #666; @include font-size(24px); font-weight: 300;}
						input{float: left; width: 70%; vertical-align: middle; color: #666; @include font-size(20px); font-weight: 300;}
					}
				}
			}
			.views-widget-filter-field_localisation_programme_locality{/*display: table-cell;*/ display: inline-block; margin: auto; width: 100%; vertical-align: middle; padding: 10px 20% 0 10%;
				label{width: 34%; display: inline-block; vertical-align: middle; text-align: right; padding-right: 20px; color: #666; @include font-size(24px); font-weight: 300;}
				.views-widget{width: 65%; display: inline-block; vertical-align: top;
					.form-item{margin: 0; float: none;}
				}
				.geofield-proximity-origin-from,
				.form-item-field-geofield-distance-distance,
				.form-item-field-geofield-distance-unit{display: none;}
			}
			.views-submit-button{display: inline-block; width: 100%; vertical-align: middle; padding: 10px 0 0 0; text-align: center;
				input{margin: 0; padding: 5px 50px;}
			}

			.views-widget-filter-secondary{display: block; width: 100%;
				fieldset{margin-bottom: 30px;
					legend{@include font-size(24px); background: none; text-align: center; padding-right: 20px;
						a:after{position: relative; content: "-"; float: right; display: block; width: 30px; height: 30px; font-size: 40px; line-height: 24px; background: #FFF; border-radius: 100%; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); margin-top: 3px; color: #666; text-align: center; font-family: 'raleway', sans-serif;}
						> span{background: none;
							a span{display: none;}
						}
					}
					.fieldset-wrapper{padding: 20px;
						.form-item{float: left; width: 25%; padding-right: 10px;}
						.form-item:nth-child(4n){padding-right: 0px;}
					}

					&.collapsed legend a:after{content: "+"; line-height: 30px;}
				}
			}


		}
	}
	.view-content{
		.field-content { padding: 5px;
			.card_ancien {background-color: #fff; -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,.1); -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,.1); box-shadow: 0 0 5px 0 rgba(0,0,0,.1); margin: 0 0 30px;
				.ribbon { position: absolute; left: -1px; top: -1px; z-index: 1; overflow: hidden; width: 75px; height: 75px; text-align: right;

					.avendre span {background: linear-gradient(#f14f3f 0,#cb4335 100%);}
					&::before,
					&::after { border-left: 3px solid #a3362a; border-right: 3px solid transparent;}

					span{ @include font-size(10px); font-weight: 700; color: #FFF; text-align: center; line-height: 20px; transform: rotate(-45deg); -webkit-transform: rotate(-45deg); width: 100px; display: block; background: #79A70A; background: linear-gradient(#9BC90D 0,#79A70A 100%); box-shadow: 0 3px 10px -5px rgba(0,0,0,1); text-shadow: 1px 1px 2px rgba(0,0,0,.25); position: absolute; top: 19px; left: -21px; text-transform: uppercase; }

				}
				.cardbox { display: block; margin-bottom: 20px; line-height: 1.42857143; background-color: #fff; border-radius: 2px; box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); transition: box-shadow .25s;

					.fav-box { position: absolute; right: 10px; @include font-size(20px); top: 4px; color: #E74C3C; cursor: pointer; background-color: rgba(224, 224, 224, 0.66); border-radius: 50%; width: 40px; height: 40px; padding: 7px 10px;}

					.title-box { position: absolute; top: 153px; background: rgba(228, 116, 30, 0.5); width: 97%; padding: 0 10px;
						.cardbox-title { @include font-size(16px); text-shadow: 1px 1px 1px #000; margin: 0!important;
							a { color: #fff; text-shadow: 1px 1px 1px #000; text-decoration: none;}
						}

					}

					.img-cardbox { width: 100%; height:200px; border-top-left-radius:2px; border-top-right-radius:2px; display:block; overflow: hidden;
						img{ width: 100%; height: 200px; object-fit:cover; transition: all .25s ease;}
					}
					.cardbox-content { padding:15px; text-align:left;
						.pieces-surface {  margin: 0 0 10px;
							img{ display: inline-block; }
						}
						.flash-prix { text-align: right; @include font-size(30px); font-weight: 600; color: #5e3327; margin: 0 0 10px;}
						.cardbox-title { margin-top:0px; font-weight: 700; @include font-size(16px);
							a { color: #000; text-decoration: none !important; }
						}
					}
					.cardbox-read-more { border-top: 1px solid #D4D4D4;
						a { text-decoration: none !important; padding:10px; font-weight:600; text-transform: uppercase }
					}
				}
				&:hover { box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19); }
			}
		}
	}
}


/* Liste Prog terrains et neufs */
.page-node-89027 .liste,
.page-node-78380 .liste,
.page-node-78408 .liste{
	.view-filters{@extend .bloc-cadre; margin-bottom: 40px;
		.views-exposed-widgets{display: table; width: 100%;
			#edit-field-type-de-bien-tid-wrapper{width: 100%; padding: 10px 0% 0 0%;
				> label{color: $couleur_base; @include font-size(20px); text-transform: uppercase; text-align: center; width: 100%; margin-bottom: 15px;}
				.form-type-select{
					.form-item{width: 19%; display: inline-block; vertical-align: top;
						[type="checkbox"]:not(:checked),
						[type="checkbox"]:checked { position: absolute; left: -9999px;
							+ label{position: relative; padding-right: 10px; cursor: pointer; padding-top: 3px; line-height: 18px; display: inline-block; vertical-align: top; height: 50px;}
							+ label:before{  position: relative; float: left; font-size: 14px; color: $orange; transition: all .2s; @extend .awesome; width: 24px; height: 24px; margin: -4px 10px 20px 0; text-align: center; line-height: 21px; border-radius: 100%;}
						}

						[type="checkbox"]:not(:checked) + label:before {border: 2px solid #999; content: "";}
						[type="checkbox"]:checked + label:before {border: 2px solid $orange; content: "\f00c";}
					}
				}
			}

			#edit-field-cogedim-fiscalite-tid-wrapper{width: 100%; padding: 10px 0% 0 0%;
				> label{color: $couleur_base; @include font-size(20px); text-transform: uppercase; text-align: center; width: 100%; margin-bottom: 15px;}
				.form-type-select{
					.form-item{width: 19%; display: inline-block; vertical-align: top;
						[type="checkbox"]:not(:checked),
						[type="checkbox"]:checked { position: absolute; left: -9999px;
							+ label{position: relative; padding-right: 10px; cursor: pointer; padding-top: 3px; line-height: 18px; display: inline-block; vertical-align: top; height: 50px;}
							+ label:before{  position: relative; float: left; font-size: 14px; color: $orange; transition: all .2s; @extend .awesome; width: 24px; height: 24px; margin: -4px 10px 20px 0; text-align: center; line-height: 21px; border-radius: 100%;}
						}

						[type="checkbox"]:not(:checked) + label:before {border: 2px solid #999; content: "";}
						[type="checkbox"]:checked + label:before {border: 2px solid $orange; content: "\f00c";}
					}
				}
			}
			.views-widget-filter-distance{
				.views-widget{width: 90%; display: inline-block; vertical-align: top;
					.form-item{margin: 0; float: none;
						label{float: left; width: 30%; vertical-align: middle; text-align: right; padding-right: 20px; color: #666; @include font-size(24px); font-weight: 300;}
						input{float: left; width: 70%; vertical-align: middle; color: #666; @include font-size(20px); font-weight: 300;}
					}
				}
			}
			.views-widget-filter-field_localisation_programme_locality{/*display: table-cell;*/ display: inline-block; margin: auto; width: 100%; vertical-align: middle; padding: 10px 20% 0 10%;
				label{width: 34%; display: inline-block; vertical-align: middle; text-align: right; padding-right: 20px; color: #666; @include font-size(24px); font-weight: 300;}
				.views-widget{width: 65%; display: inline-block; vertical-align: top;
					.form-item{margin: 0; float: none;}
				}
				.geofield-proximity-origin-from,
				.form-item-field-geofield-distance-distance,
				.form-item-field-geofield-distance-unit{display: none;}
			}
			.views-submit-button{display: inline-block; width: 100%; vertical-align: middle; padding: 10px 0 0 0; text-align: center;
				input{margin: 0; padding: 5px 50px;}
			}

			.views-widget-filter-secondary{display: block; width: 100%;
				fieldset{margin-bottom: 30px;
					legend{@include font-size(24px); background: none; text-align: center; padding-right: 20px;
						a:after{position: relative; content: "-"; float: right; display: block; width: 30px; height: 30px; font-size: 40px; line-height: 24px; background: #FFF; border-radius: 100%; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); margin-top: 3px; color: #666; text-align: center; font-family: 'raleway', sans-serif;}
						> span{background: none;
							a span{display: none;}
						}
					}
					.fieldset-wrapper{padding: 20px;
						.form-item{float: left; width: 25%; padding-right: 10px;}
						.form-item:nth-child(4n){padding-right: 0px;}
					}

					&.collapsed legend a:after{content: "+"; line-height: 30px;}
				}
			}
		}
	}
	.views-row{  width: 33%;float: left; padding: 10px;
		.terrains{box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); padding: 5px; position: relative;

			.info_supp{position: absolute; z-index: 10; top:30px; padding: 10px 3px; left: 0; background: $orange; color:#FFF; }
			.miniature-terrains{background: $couleur_base; overflow: hidden; display: block; height: 310px;
				img{transform:scale(1.1);  opacity: 0.7; @extend .transition; object-fit:cover; width: 100%; height: 100%;}
			}
			.titre-terrains{position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); text-align: center; display: block; color: #FFF; text-transform: uppercase; @include font-size(20px); text-decoration: none; text-shadow: 0 0 5px #000, 0 0 5px #000; @extend .gradiant_noir; width: calc(100% - 10px); padding: 10px 0;}

			&:hover .miniature-terrains img{transform:scale(1); opacity: 1}

			&.termine{
				.miniature-terrains{background: #333;
					img{opacity: 0.5;}
				}
			}
		}
	}
}

#block-block-36{margin: 50px 0 30px 0;

	.col-md-6,
	.col-md-3{border-left:1px solid #BBB; padding: 0 30px; min-height: 130px;
		i{color: $orange; font-size: 40px; float: left; width: 60px;}
		.titre-bloc{font-weight: bold; font-size: 18px; color: #000; line-height: 24px; height: 35px;}
		p{font-size: 14px; line-height: 18px; color: #666}
		&:first-child{border: none;}
	}
}

.voir-aussi .jcarousel-container{ width: 100%; margin-bottom: 40px;
	.jcarousel-clip{width: 90%; margin: auto;
		.jcarousel-item{  width: 50%;float: left; padding: 10px;
			.terrains{box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); padding: 5px; position: relative;
				.miniature-terrains{background: $couleur_base; overflow: hidden; display: block; height: 310px;
					img{transform:scale(1.1);  opacity: 0.7; @extend .transition; object-fit:cover; width: 100%; height: 100%;}
				}
				.titre-terrains{position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); text-align: center; display: block; color: #FFF; text-transform: uppercase; @include font-size(20px); text-decoration: none; text-shadow: 0 0 5px #000, 0 0 5px #000; @extend .gradiant_noir; width: calc(100% - 10px); padding: 10px 0;}

				&:hover .miniature-terrains img{transform:scale(1); opacity: 1}
			}
		}
	}
}

.node-type-cogedim-flux-content,
.node-type-property{
	#block-system-main{
		.ds-1col{
			section{background: #EEEEEE; position: relative; z-index: 2;

				.bg_blanc{background: #FFF;  padding: 20px 0;
					a{color: #333; @extend .oswald; @include font-size(16px);
						i:before,
						&:before,
						&:after{color: $orange; text-align: center; display: inline-block; width: 35px; font-size: 20px;}
					}

					.btn{position: absolute; transform:translateX(-50%); left: 50%;margin-top: -8px;
						a{color: #FFF;  }
					}

					#prevnext-previous{float: right; margin-right: 40px;
						a:before{content: "\f053"; @extend .awesome;}
					}
					#prevnext-next{float: right;
						a:after{content: "\f054"; @extend .awesome;}
					}
				}
				.group-bloc-haut{position: relative; padding: 10px 15px 30px 15px;
					.field-name-field-certification{ position: absolute; right: 0; top: 0;
						.field-item {float: right; margin-top: 8px;}
					}
					h2{background: none; text-align: left; @include font-size(30px); color: #fff; width: calc(100% - 240px);
						span{padding: 15px; background: $orange; font-weight: bold; text-shadow: 2px 2px 2px #000; line-height: 55px;}
					}
					.header-info{ background: #fff; display: flex; flex-direction: row; flex-flow: nowrap;
						.field-name-field-info-suppl-mentaire{position: absolute; z-index: 100; left: 385px; margin-top: 60px; padding: 5px 10px; background: $orange; color: #FFF;}
						.field-slideshow-wrapper{min-width: 70%; width: 70%; position: relative; padding: 0 0 5px 0; background: #018c92;

                            iframe.media-youtube-player{ max-height: 447px; max-width: 100%; }

							.field-slideshow-controls{ /* position: absolute; z-index: 25; top: 225px; */

								.prev{ width: 35px; position: absolute; background-position: 0px center; left: 10px; background-size: 140px 65px; height: 65px; display: inline-block; background-image: url('../images/nav.png'); background-color: transparent; background-repeat: no-repeat; text-indent: -9999px; opacity: 0.8;
									&:hover{ opacity: 1; }
								}

								.next{background-position: -35px center; right: 10px; position: absolute; width: 35px; background-size: 140px 65px; height: 65px; display: inline-block; background-image: url('../images/nav.png'); background-color: transparent; background-repeat: no-repeat; text-indent: -9999px; opacity: 0.8;
									&:hover{ opacity: 1; }
								}
							}

							.field-slideshow-carousel-wrapper{max-width: 592px; margin: 0 auto;
								li{ padding: 0; opacity: 1; margin: 0 2px; min-width: 70px!important;
									&.activeSlide img{filter: grayscale(0%);}
								}
								img{filter: grayscale(100%);border: 3px solid #FFF;}
								.carousel-prev{position: absolute; left: 75px; bottom: 30px; background: #FFF; border-radius:100%; width: 20px; height: 20px; line-height: 18px; text-align: center;}
								.carousel-next{position: absolute; right: 75px; bottom: 30px; background: #FFF;  border-radius:100%; width: 20px; height: 20px; line-height: 18px; text-align: center;}
							}
							.carte{display: block; position: absolute; width: 80px; height: 80px; background: url('../images/carte.png') no-repeat; top: 10px; right: 10px; z-index: 120; cursor: pointer;}
							.cbox-open{ position: absolute; top: 130px; right: 30px; z-index: 100;
								a{
									i{ opacity: 0.8; font-size: 60px; color: #FFF; display: block; pointer-events:none; }

									&:hover { text-decoration: none;
										&> i{ opacity: 1; font-size: 80px; }
									}
								}

							}
							/*
							&:after{content: "\f00e"; @extend .awesome; position: absolute; top: 130px; right: 30px; color: #FFF; font-size: 40px; display: block; z-index: 100; opacity: 0.8; pointer-events:none; }
							&:hover:after{opacity: 1;}
							*/
						}
						.group-header-info-right{min-width: 30%; width: 30%; position: relative; padding: 20px;
							h3{margin-top: 0;
								.postal-code{display: none;}
								.locality{color: $orange; @extend .oswald; @include font-size(20px);}
							}
							.avantages{
								.field-item{@extend .carre; margin-bottom: 15px;}
							}
							.group-contact-brochure{position: absolute; bottom: 20px; width: 88%;

								.field-name-field-prix-ht{ @include font-size(30px); text-align: center; line-height: 60px; font-weight: 700; @extend .oswald; }

								.field{
									a{display: block; width: 100%; text-align: left; padding: 10px 15px 10px 10px;
										&:before,
										i:before{color: #FFF; font-size: 20px; margin: 0 10px; display: inline-block; vertical-align: middle;}
									}
								}

								.file-document{
                                    margin-bottom: 10px;
                                    display: block;
                                    width: 100%;
                                    text-align: left;
                                    padding: 10px 15px 10px 10px;
                                }

								.file-document,
								.field-name-voir-prix,
								.field-name-contacter-l-agence {
									a{ font-family: "Open Sans Condensed", sans-serif; }
								}

								.contextual-links-wrapper,
								.field-name-field-folder{display: none}
							}
							.btn-carr{  position: absolute; bottom: 120px; width: 88%;
								.voir-video{display: inline-block; width: 25%; height: 70px; background: url('../images/voir-video.png') no-repeat; background-size: contain; cursor: pointer;}
								.troiscentsoix{display: inline-block; width: 25%; height: 70px; background: url('../images/360.png') no-repeat; background-size: contain; cursor: pointer;}
								.carte{display: inline-block; width: 25%; height: 70px; background: url('../images/carte.png') no-repeat; background-size: contain; cursor: pointer;}
								.contactus{ display: inline-block; width: 25%; height: 70px; background: url('../images/contact.png') no-repeat; background-size: contain; cursor: pointer;}

								& > div:hover {
									border: 1px solid #018c92;
									border-radius: 3px;
								}
							}
						}
						&:after{content: ""; width: 100%; clear: both; display: block;}
					}
				}
			}
			article{ /* padding: 150px 15px 30px 15px; margin-top: -120px; */ min-height: 760px; position: relative;

/* Programmes neufs */
				&.article-lodgim-terrain,
				&.article-lodgim-neuf{

					.field-name-simulateur{ border: 1px solid #d9d9d9; width: 342px; margin: 0; position: absolute; top: 350px;
						.titre-simulateur{background: $couleur_base; color: #FFF; text-transform: uppercase; padding: 10px 5px; text-align: center;}
						form{padding: 0 10px;
							input.form-text{ padding: 5px 40px 5px 10px; text-align: right;}
							.field-suffix{position: absolute; width: 30px; border-left: 1px solid #d9d9d9; line-height: 36px; text-align: center; color: #999; top: 0px; right: 0;}
							#edit-submitted-duree-du-pret{
								.form-item{display: inline-block; vertical-align: top; width: 32%; margin-top: 0;}

							}

							.webform-component-number{
								label{position: absolute; color: #999; top: 6px; left: 10px;}
							}

							label{text-align: center; color: #666; font-weight: normal; @include font-size(13px);}
							.form-item{position: relative;}
							.form-actions{position: absolute; margin-top: -57px;}

							.webform-component--frais-de-notaire,
							.webform-component--montant-de-lemprunt{display: none;}
							#edit-submitted-mensualite{text-align: right;}
						}
					}

					.field-iframe { overflow: hidden;
						iframe{ position: relative; top: -40px; }
					}

					.vertical-tabs{ border: none; width: 100%; margin: 0;
						.vertical-tabs-list{width: 341px; margin: 0;
							.vertical-tab-button{list-style-type: none; border-right-width: 0; border-bottom: 1px solid #DDD;  margin: auto; padding: 0; border: 1px solid #d9d9d9;
							background: whiteSmoke;
							background: -moz-linear-gradient(top, #ffffff 0%, #f2f2f2 100%);
							background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #f2f2f2));
							background: -webkit-linear-gradient(top, #ffffff 0%, #f2f2f2 100%);
							background: -o-linear-gradient(top, #ffffff 0%, #f2f2f2 100%);
							background: -ms-linear-gradient(top, #ffffff 0%, #f2f2f2 100%);
							background: linear-gradient(top, #ffffff 0%, #f2f2f2 100%);
							box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
							@extend .transition; text-align: left;

								&:nth-child(1) a:before{ @extend .awesome; content: "\f05a";}
								&:nth-child(2) a:before{ @extend .awesome; content: "\f1ea";}
								&:nth-child(3) a:before{ @extend .awesome; content: "\f019";}
								&:nth-child(4) a:before{ @extend .awesome; content: "\f041";}

								a{text-decoration: none; text-transform: uppercase; padding: 10px 5px;
									&:before{font-size: 20px; color: #999; display: inline-block; width: 34px; vertical-align: top; text-align: center; color: $orange; }
									strong{@include font-size(16px); font-family: "Open Sans Condensed", sans-serif; text-decoration: none;}
								}
								&.selected{background: $orange; border-bottom: 1px solid #DDD; border-right-width: 0; z-index: 10; position: relative; width: 101%;
									a{
										&:before{color: #FFF; }
										strong{color: #FFF;}
									}
								}


							}
						}
						.vertical-tabs-panes{width: calc(100% - 341px); margin: 0 0 0 341px; min-height: 695px; /*border: 1px solid #d9d9d9; z-index: 9; padding: 10px; overflow: hidden; */
							fieldset{width: 100%;}
							.group-tabphotos{
								h3{@extend .oswald; @include font-size(24px); margin-top: 0; margin-bottom: 20px;}

								table{@extend .sticky-table; width: 80%; margin: 40px auto;text-align: inherit;
									p{margin-bottom: 5px;}
									td{border-bottom: 1px solid #dfdfdf;  text-align: inherit; color: #333!important}
								}


								table.table-left{text-align: left;}

								dl{	margin: 2em 0; padding: 0;

									dt{ background-color: $couleur_base; color: #fff; padding: .5em .5em; font-weight: bold; text-align: center; text-transform: uppercase; border-left: 1px solid #d8d8d8; border-right: 1px solid #d8d8d8; border-top: 1px solid #d8d8d8; }

									dd{ margin: 0 0 1em 0; text-align: center; padding: 1em .5em; font-style: italic; border-left: 1px solid #d8d8d8; border-right: 1px solid #d8d8d8;	border-bottom: 7px solid $couleur_base; }

								}

								strong{color: $orange;}

								blockquote{padding: 40px; margin: 0; border: 1px solid #dfdfdf; border-bottom: 7px solid $couleur_base; color: #666; font-style: italic;
									p{@include font-size(18px);}
									&:before{left: auto; right: 30px; top: -30px; font-style: normal; font-size: 40px; color: $couleur_base; background: #FFF; padding: 20px; @extend .awesome; content: "\f10e";}
								}

								ul {list-style: none; padding: 0; margin: 0;
									li{ padding-left: 1em; text-indent: -.7em; list-style: none;
										div{ display: inline; }
									}
									li:before {content: "■ ";color: $orange; margin-right: 10px;}
								}
								ul.check{
									li:before {color: $orange;  @extend .awesome; content: "\f00c";}
								}

								ul.main{
									li:before {color: $orange;  @extend .awesome; content: "\f0a4";}
								}


								ol {list-style: none; padding: 0; margin: 0;
									li{list-style: none; clear: both; margin-bottom: 60px; min-height: 65px;}

									li:before {float: left; margin-right: 30px; font-family: arial; color: $orange; font-size:40px; width: 60px; height: 60px; text-align: center; line-height: 60px; border-radius: 100%; box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); }


									@for $i from 1 through 20 {
										li:nth-child(#{$i}):before {content: "#{$i}";}

									}
								}

								ol.decale{
									li:nth-child(2n+2):before{float: right; margin-left: 30px; margin-right: 0;}
								}


								.camembert{width: 100%; border: none;
									td:first-child{width: 50%;
										img{margin:auto;}
									}
									td:last-child{width: 50%;}
									td{border: none; vertical-align: middle; text-align: left;
										ul{border: 1px solid #dfdfdf; border-bottom: 7px solid $couleur_base;
											li{ margin: 20px 20px;}
											li:before{content: ""; width: 30px; height: 30px; display: inline-block; vertical-align: middle;}
											li:nth-child(1):before{background: #2c8692}
											li:nth-child(2):before{background: $orange}
											li:nth-child(3):before{background: #451e00}
											li:nth-child(4):before{background: #79d3de}
										}
									}
								}


								.tab-etapes{text-align: left;width: 100%; border: none;
									th{background: $bleu; border: none;border: 1px solid rgb(217, 217, 217);
										h3{color: #FFF; @extend .raleway;}
									}
									td{background: $bleu; border: 1px solid rgb(217, 217, 217);color: #FFF!important;
										p,
										li,
										div{margin-bottom: 5px; color: #FFF}
									}
									th:last-child{background: $orange;}
									td:last-child{background: $orange; color: #FFF; border: 1px solid #FFF;
										strong{color: #FFF; }
										h3{color: #FFF;}
									}
									h3{margin: 10px; text-align: left;}
								}

							}
							.group-tablots{
								.miniature{float: left; position: relative; margin: 20px 60px;
                                    img{ max-height: 300px; width: auto; }
									span{position: absolute; top: 50%; left: 50%; transform:translate(-50%, -50%); margin: 0!important; opacity: 0.9; @include font-size(28px);}
								}
								h3{@extend .oswald; @include font-size(24px); margin-top: 0; margin-bottom: 20px;}
								 .modal-dialog{/*width: 990px; */ position: absolute; transform:translateX(-50%); width: initial; left: 50%;
                                    .modal-header{ background : $orange;
    									h4{text-align: center; font-weight: normal; color: #fff;}
    									.close{font-size: 30px; color: #fff;}
                                    }
								 }

								#map.in{
									#lodgim_map{
                                        display: block!important;
                                        padding-right: 0!important;
                                        min-height: 335px;
                                        overflow: visible!important;
                                        svg{
                                            height: 100%;
                                        }
                                    }
								}

								.field-name-consulter-grille-des-lots .btn{ margin: 50px 0 70px 100px;}
							}
							.group-tabdocuments{
								table{text-align: left;
									tr{
										th:last-child,
										td:last-child{ display: none; }
									}
								}
							}
							.group-tablocalisation{

							}

						}

						.field-iframe div#header { display: none!important; }

					}
				}
/* End Programmes neufs */

/* Programmes anciens */
				.field-group-accordion-anciens{
					h3.ui-accordion-header-active{border: 3px solid $orange;}
					h3.ui-accordion-header{background: $orange;
						a, span{color: #FFF; @include font-size(16px); font-weight: bold; text-decoration: none;}
					}
					p{font-family: 'Open Sans',sans-serif;}
				}

				&.article-lodgim-ancien{

					aside {border: 4px solid $orange; margin-right: 10px; border-radius: 5px; padding: 10px; }

					.field-name-body{max-width: 1140px; margin: auto; overflow: hidden;
						h2:after{left: -50%; width: 200%;}
						h3{@extend .oswald; text-transform: none; margin-top: 50px;}

						table{@extend .sticky-table; width: 80%; margin: 40px auto;text-align: inherit;
							p{margin-bottom: 5px;}
							td{border-bottom: 1px solid #dfdfdf;  text-align: inherit; color: #333!important}
						}

						table.table-left{text-align: left;}

						dl{	margin: 2em 0; padding: 0;

							dt{ background-color: $couleur_base; color: #fff; padding: .5em .5em; font-weight: bold; text-align: center; text-transform: uppercase; border-left: 1px solid #d8d8d8; border-right: 1px solid #d8d8d8; border-top: 1px solid #d8d8d8; }

							dd{ margin: 0 0 1em 0; text-align: center; padding: 1em .5em; font-style: italic; border-left: 1px solid #d8d8d8; border-right: 1px solid #d8d8d8;	border-bottom: 7px solid $couleur_base; }

						}

						strong{color: $orange;}

						blockquote{padding: 40px; margin: 0; border: 1px solid #dfdfdf; border-bottom: 7px solid $couleur_base; color: #666; font-style: italic;
							p{@include font-size(18px);}
							&:before{left: auto; right: 30px; top: -30px; font-style: normal; font-size: 40px; color: $couleur_base; background: #FFF; padding: 20px; @extend .awesome; content: "\f10e";}
						}

						ul {list-style: none; padding: 0; margin: 0;
							li{ padding-left: 1em; text-indent: -.7em; list-style: none;}
							li:before {content: "■ ";color: $orange; margin-right: 10px;}
						}
						ul.check{
							li:before {color: $orange;  @extend .awesome; content: "\f00c";}
						}

						ul.main{
							li:before {color: $orange;  @extend .awesome; content: "\f0a4";}
						}


						ol {list-style: none; padding: 0; margin: 0;
							li{list-style: none; clear: both; margin-bottom: 60px; min-height: 65px;}

							li:before {float: left; margin-right: 30px; font-family: arial; color: $orange; font-size:40px; width: 60px; height: 60px; text-align: center; line-height: 60px; border-radius: 100%; box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); }


							@for $i from 1 through 20 {
								li:nth-child(#{$i}):before {content: "#{$i}";}

							}
						}

						ol.decale{
							li:nth-child(2n+2):before{float: right; margin-left: 30px; margin-right: 0;}
						}


						.camembert{width: 100%; border: none;
							td:first-child{width: 50%;
								img{margin:auto;}
							}
							td:last-child{width: 50%;}
							td{border: none; vertical-align: middle; text-align: left;
								ul{border: 1px solid #dfdfdf; border-bottom: 7px solid $couleur_base;
									li{ margin: 20px 20px;}
									li:before{content: ""; width: 30px; height: 30px; display: inline-block; vertical-align: middle;}
									li:nth-child(1):before{background: #2c8692}
									li:nth-child(2):before{background: $orange}
									li:nth-child(3):before{background: #451e00}
									li:nth-child(4):before{background: #79d3de}
								}
							}
						}


						.tab-etapes{text-align: left;width: 100%; border: none;
							th{background: $bleu; border: none;border: 1px solid rgb(217, 217, 217);
								h3{color: #FFF; @extend .raleway;}
							}
							td{background: $bleu; border: 1px solid rgb(217, 217, 217);color: #FFF!important;
								p,
								li,
								div{margin-bottom: 5px; color: #FFF}
							}
							th:last-child{background: $orange;}
							td:last-child{background: $orange; color: #FFF; border: 1px solid #FFF;
								strong{color: #FFF; }
								h3{color: #FFF;}
							}
							h3{margin: 10px; text-align: left;}
						}


					}

					.field-name-affichage-dpe-ges{
						.graphique{
							.contenu_graphique{position: relative;}
							.valeur{position: absolute; left: 63%; color: #FFF; line-height: 32px; height: 32px; font-weight: 800;}
							&.val_a .valeur{top: 2%;}
							&.val_b .valeur{top: 13%;}
							&.val_c .valeur{top: 24%;}
							&.val_d .valeur{top: 35%;}
							&.val_e .valeur{top: 45%;}
							&.val_f .valeur{top: 56%;}
							&.val_g .valeur{top: 67%;}
							&.val_h .valeur{top: 78%;}
							&.val_i .valeur{top: 89%;}
						}
					}

					.field-name-simulateur{ border: 1px solid #d9d9d9; width: 280px; margin: 0;
						.titre-simulateur{background: $couleur_base; color: #FFF; text-transform: uppercase; padding: 10px 5px; text-align: center;}
						form{padding: 0 10px;
							input.form-text{ padding: 5px 40px 5px 10px; text-align: right;}
							.field-suffix{position: absolute; width: 30px; border-left: 1px solid #d9d9d9; line-height: 36px; text-align: center; color: #999; top: 0px; right: 0;}
							#edit-submitted-duree-du-pret{
								.form-item{display: inline-block; vertical-align: top; width: 32%; margin-top: 0;}

							}

							.webform-component-number{
								label{position: absolute; color: #999; top: 6px; left: 10px;}
							}

							label{text-align: center; color: #666; font-weight: normal; @include font-size(13px);}
							.form-item{position: relative;}
							.form-actions{position: absolute; margin-top: -57px;}

							.webform-component--frais-de-notaire,
							.webform-component--montant-de-lemprunt{display: none;}
							#edit-submitted-mensualite{text-align: right;}
						}
					}

				}

/* End Programmes anciens */

			}
		}
	}
}



/* FRAIS DE NOTAIRE */
#block-block-32{display: none;}

#lodgim_map{
	path{opacity: 0; cursor:pointer;}
	path:hover{opacity: 1;}

	.visible{display: block!important;}

	.bloc-map{position: absolute; z-index: 100000; background: #FFF; left:0; top:0; border-right : 1px solid #DDD; padding: 10px 15px 10px 0px; width:255px; display: none;
		.picto-ok{color:green; font-size:20px; font-weight:900;}
		.picto-attente{color:blue; font-size:20px; font-weight:900;}
		.picto-no{color:red; font-size:20px; font-weight:900;}
		.num-montant{font-weight:900; font-size:15px; color:$orange; text-align: right; @include font-size(24px);
			span{color: #666;  @include font-size(16px);}
		}
		fieldset{border-bottom: 7px solid $bleu; margin: 30px 0 }

		.VENDU{
			hr,
			.num-montant{display: none;}
		}
	}
}

.mapTooltip {
	position : fixed;
	background-color : #fff;
	moz-opacity:0.70;
	opacity: 0.70;
	filter:alpha(opacity=70);
	border-radius:10px;
	padding : 10px;
	z-index: 1000;
	max-width: 200px;
	display:none;
	color:#343434;
}

.node-type-article,
.node-type-page{
	#content-wrapper{border: none;
		.group-contenu{overflow: hidden;
			.field-name-body{max-width: 1140px; margin: auto;
				h2:after{left: -50%; width: 200%;}
				h3{@extend .oswald; text-transform: none; margin-top: 50px;}

				table{@extend .sticky-table; width: 80%; margin: 40px auto;text-align: inherit;
					p{margin-bottom: 5px;}
					td{border-bottom: 1px solid #dfdfdf;  text-align: inherit; color: #333!important}
				}

				table.table-left{text-align: left;}

				dl{	margin: 2em 0; padding: 0;

					dt{ background-color: $couleur_base; color: #fff; padding: .5em .5em; font-weight: bold; text-align: center; text-transform: uppercase; border-left: 1px solid #d8d8d8; border-right: 1px solid #d8d8d8; border-top: 1px solid #d8d8d8; }

					dd{ margin: 0 0 1em 0; text-align: center; padding: 1em .5em; font-style: italic; border-left: 1px solid #d8d8d8; border-right: 1px solid #d8d8d8;	border-bottom: 7px solid $couleur_base; }

				}

				strong{color: $orange;}

				blockquote{padding: 40px; margin: 0; border: 1px solid #dfdfdf; border-bottom: 7px solid $couleur_base; color: #666; font-style: italic;
					p{@include font-size(18px);}
					&:before{left: auto; right: 30px; top: -30px; font-style: normal; font-size: 40px; color: $couleur_base; background: #FFF; padding: 20px; @extend .awesome; content: "\f10e";}
				}

				ul {list-style: none; padding: 0; margin: 0;
					li{ padding-left: 1em; text-indent: -.7em; list-style: none;}
					li:before {content: "■ ";color: $orange; margin-right: 10px;}
				}
				ul.check{
					li:before {color: $orange;  @extend .awesome; content: "\f00c";}
				}

				ul.main{
					li:before {color: $orange;  @extend .awesome; content: "\f0a4";}
				}


				ol {list-style: none; padding: 0; margin: 0;
					li{list-style: none; clear: both; margin-bottom: 60px; min-height: 65px;}

					li:before {float: left; margin-right: 30px; font-family: arial; color: $orange; font-size:40px; width: 60px; height: 60px; text-align: center; line-height: 60px; border-radius: 100%; box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); }


					@for $i from 1 through 20 {
						li:nth-child(#{$i}):before {content: "#{$i}";}

					}
				}

				ol.decale{
					li:nth-child(2n+2):before{float: right; margin-left: 30px; margin-right: 0;}
				}


				.camembert{width: 100%; border: none;
					td:first-child{width: 50%;
						img{margin:auto;}
					}
					td:last-child{width: 50%;}
					td{border: none; vertical-align: middle; text-align: left;
						ul{border: 1px solid #dfdfdf; border-bottom: 7px solid $couleur_base;
							li{ margin: 20px 20px;}
							li:before{content: ""; width: 30px; height: 30px; display: inline-block; vertical-align: middle;}
							li:nth-child(1):before{background: #2c8692}
							li:nth-child(2):before{background: $orange}
							li:nth-child(3):before{background: #451e00}
							li:nth-child(4):before{background: #79d3de}
						}
					}
				}


				.tab-etapes{text-align: left;width: 100%; border: none;
					th{background: $bleu; border: none;border: 1px solid rgb(217, 217, 217);
						h3{color: #FFF; @extend .raleway;}
					}
					td{background: $bleu; border: 1px solid rgb(217, 217, 217);color: #FFF!important;
						p,
						li,
						div{margin-bottom: 5px; color: #FFF}
					}
					th:last-child{background: $orange;}
					td:last-child{background: $orange; color: #FFF; border: 1px solid #FFF;
						strong{color: #FFF; }
						h3{color: #FFF;}
					}
					h3{margin: 10px; text-align: left;}
				}


			}
		}
	}
}

.liste-actu {

	.views-bootstrap-grid-plugin-style{

		.row [class*=col]{ padding: 10px;}
		.row{ margin-bottom: 30px;
			.actu-date{color: #999;}
			a.actu-photo{position: relative; text-decoration: none!important;  display: block;
				.actu-ban{width: 100%; overflow: hidden; display: block; height: 200px;
					img{transform:scale(1.1); @extend .transition; object-fit:cover; height: 100%;}
				}
				.btn-plus{@extend .transition; position: relative; float: right; display: block; width: 50px; height: 50px; font-size: 70px; line-height: 50px; background: #FFF; border-radius:100%; box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5); margin-top: -25px; margin-right: 15px; color: #666; text-align: center;font-family: 'raleway', sans-serif;}
				h3{@include font-size(20px); height: 65px;}
				.body{color: #666;}
			}


			&:hover{
				a.actu-photo{
					.btn-plus{transform: rotate(180deg); background: $orange; color: #FFF;}
					.actu-ban{
						img{transform:scale(1);}
					}
				}
			}
		}
	}

}

.page-search-node{
	#block-system-main{
		.search-info,
		.search-form{display: none;}
		strong{color: $orange;}

		ol {list-style: none; padding: 0; max-width: 1140px; margin: auto;
			li{list-style: none; clear: both; margin-bottom: 60px; min-height: 65px;}

			li:before {float: left; margin-right: 30px; font-family: arial; color: $orange; font-size:40px; width: 60px; height: 60px; text-align: center; line-height: 60px; border-radius: 100%; box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); margin-bottom: 30px; }
			@for $i from 1 through 20 {
				li:nth-child(#{$i}):before {content: "#{$i}";}

			}
		}
		.item-list{
			li{display: inline-block; vertical-align: top; float: none;}
		}
	}
}

.page-lexique{
	#block-system-main{max-width: 1140px; margin: auto;
		.lexicon-links{background: none; margin-top: 30px;

		}
	}
}

.page-search{
	#block-system-main{max-width: 1140px; margin: auto;	}
}

/* ==========================================================================
|  ADMIN
========================================================================== */
.not-logged-in.page-user,
.page-toboggan{
	h1{ @extend .oswald; 
		@include font-size(50px);
	    color: #018c92;
	    font-family: 'Oswald',sans-serif;
	    font-weight: 200!important;
	    text-transform: uppercase;
	    position: relative;
	    text-align: center;
	    margin-bottom: 30px;
	}
	#content-wrapper{ 
		max-width: 1170px; 
		margin: 0 auto;
	}
}

@media screen and (max-width: 480px){
	.not-logged-in.page-user h1,
	.page-toboggan h1 {
		@include font-size(22px);
	}
}
@media screen and (max-width: 767px){
	.not-logged-in.page-user h1,
	.page-toboggan h1 {
		@include font-size(30px);
	}
}
@media screen and (max-width: 1023px){
	.not-logged-in.page-user h1,
	.page-toboggan h1 {
		@include font-size(36px);
	}
}

.page-node-88435{
	.block-user-login-instance .content{ max-width: 1170px; margin: 0 auto;}
}
.page-node-45254 {
	#block-user-login .content{ max-width: 1170px; margin: 0 auto;}
}
/* ==========================================================================
|  ADMIN
========================================================================== */

/* Partenaires Immo */
.page-node-45254,  .page-user {

	#block-system-user-menu, #block-menu-menu-menu-partenaires{ margin: 0 auto;
		ul.menu{ width: 100%;
			li{display: inline-block; vertical-align: top; width: 23%; margin: 1% 1% 1% 0;
				a{padding: 0 20px;  color: #FFF; font-size:18px; float: right; display: block; width: 100%; height: 60px; transition: all 0.3s linear; line-height: 60px; background: $couleur_base; text-decoration: none!important;
					&:before{width: 70px; letter-spacing: 100px; font-size:50px; font-family:FontAwesome; display: inline-block; color: #FFF; float: left;}
					&:hover{background: $orange;}
				}
				.deconnect{background: #da3b38}
				.creer{background: #60a917}
				.info{background: #00aba9}
			}
		}
	}

	.view-partenaires-programmes{
		.sticky-header{ top: 140px!important; z-index: 100; }
	}

}

/* Partenaires Constructeurs */
.page-node-88435 {

	#block-system-user-menu, #block-menu-menu-menu-constructeurs{ margin: 0 auto;
		ul.menu{ width: 100%;
			li{display: inline-block; vertical-align: top; width: 23%; margin: 1% 1% 1% 0;
				a{padding: 0 20px;  color: #FFF; font-size:18px; float: right; display: block; width: 100%; height: 60px; transition: all 0.3s linear; line-height: 60px; background: $couleur_base; text-decoration: none!important;
					&:before{width: 70px; letter-spacing: 100px; font-size:50px; font-family:FontAwesome; display: inline-block; color: #FFF; float: left;}
					&:hover{background: $orange;}
				}
				.deconnect{background: #da3b38}
				.creer{background: #60a917}
				.info{background: #00aba9}
			}
		}
	}

	.logo_constructeur img{ max-width: 250px; }
}

#cboxLoadedContent {
	.view-liste-des-lots-investissement{ background: #fff;
		.view-header{ @include font-size(50px); color: $couleur_base; font-family: 'Oswald', sans-serif; font-weight: 200; text-transform: uppercase; position: relative; text-align: center; margin-bottom: 30px; }
	}
}

#cboxWrapper{ background: #018c92; }

#cboxSlideshow{ position: absolute; bottom: -30px; left: 45px; color: #fff; }

#cboxCurrent{ color: #fff; bottom: -30px; }

#cboxPrevious{ position: absolute;
    top: 50%;
    left: 45px;
    width: 35px;
    height: 65px;
    background-position: 0px center;
    background-size: 140px 65px;
    background-image: url(../images/nav.png);
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-color: transparent;
    opacity: 0.8;

	&:hover{ opacity: 1; background-position: 0px center; }
}

#cboxNext{ position: absolute;
    top: 50%;
    right: 45px;
    width: 35px;
    height: 65px;
    background-position: -35px center;
    background-size: 140px 65px;
    background-image: url(../images/nav.png);
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-color: transparent;
    opacity: 0.8;

	&:hover{ opacity: 1; background-position: -35px center; }
}

/* -----------------------------------------
   Shared Styles
----------------------------------------- */

/* Mobile */
@media only screen and (max-width: 480px) {

	table.responsive { margin-bottom: 0; }

	.pinned { position: absolute; left: 0; top: 0; background: #fff; width: 50%; overflow: hidden; overflow-x: scroll; border-right: 1px solid #ccc; border-left: 1px solid #ccc; }
	.pinned table { border-right: none; border-left: none; width: 100%; }
	.pinned table th, .pinned table td { white-space: nowrap; }
	.pinned table th:first-child, .pinned table td:first-child{white-space: normal;}

	.pinned td:last-child { border-bottom: 0; }

	div.table-wrapper { position: relative; margin-bottom: 20px; overflow: hidden; border-right: 1px solid #ccc; }
	div.table-wrapper div.scrollable { margin-left: 50%; }
	div.table-wrapper div.scrollable { overflow: scroll; overflow-y: hidden; }



	// table.responsive td, table.responsive th { position: relative; white-space: nowrap; overflow: hidden; }
	table.responsive th:first-child, table.responsive td:first-child, table.responsive td:first-child, table.responsive.pinned td { display: none; }
}

/* -----------------------------------------
   RGPD BANNER EU COOKIE
----------------------------------------- */
#sliding-popup{
    border-radius: 2px;
    -webkit-box-shadow: 0 17px 17px rgba(0,0,0,.15), 0 27px 55px rgba(0,0,0,.3);
    box-shadow: 0 17px 17px rgba(0,0,0,.15), 0 27px 55px rgba(0,0,0,.3);
    font: 14px/20px Roboto,sans-serif;
/*
    margin: 24px;
    max-height: calc(100% - 48px);
    max-width: calc(100% - 48px);
    padding: 8px;
*/
}

#sliding-popup .popup-content{
	width: 100%;
    display: flex;
    padding: 60px;
    max-width: none;
    max-height: 105vh;
    min-height: 225px!important;
}

#sliding-popup .popup-content #popup-buttons {
    float: none;
    max-width: none;
    display: flex!important;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    padding-left: 60px;
}

#sliding-popup button.agree-button {
    min-width: 250px;
    background: #e4741e;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 2.6rem;
    padding: 5px 25px;
    font-family: 'Open Sans',sans-serif;
    font-weight: 500 !important;
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 4px;

	&:hover{ background: #333; }

}
#sliding-popup button.decline-button,
#sliding-popup button.find-more-button{ display: none; }

#sliding-popup .popup-content #popup-text {
    max-width: 100%;

	p{ display: inline;}

	button{ border: none; background: none; color: #018c92;text-decoration: underline;}
}

#sliding-popup.sliding-popup-bottom, #sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-banner, .eu-cookie-withdraw-tab {
    background: #FFF;
    opacity: 0.9;
	border: 1px solid #018c92;

		a, a:hover, a:focus, .h4{ color: #000; }
		small{ color: #018c92; }
}

#sliding-popup.sliding-popup-bottom a, #sliding-popup.sliding-popup-bottom a:hover, #sliding-popup.sliding-popup-bottom a:focus, #sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-banner a, #sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-banner a:hover, #sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-banner a:focus, .eu-cookie-withdraw-tab a, .eu-cookie-withdraw-tab a:hover, .eu-cookie-withdraw-tab a:focus{
    color: #e4741e;
}

/* -----------------------------------------
   WEBFORM VENDRE SON TERRAIN
----------------------------------------- */

.webform-client-form-88626{
	fieldset{ border: none; }
	.webform-wrapper-surface{ display: inline-flex;
			span.field-suffix { margin-left: 5px; }
	}
}
/* -----------------------------------------
   WEBFORM SIMULATEUR LOI PINEL et PTZ
----------------------------------------- */

#block-webform-client-block-82972,
#block-webform-client-block-82970{max-width: 850px; margin: auto;
	fieldset{margin-top: 40px;}
	legend{background:$couleur_base; color: #fff!important; text-transform: uppercase; padding: 10px 5px; text-align: center; width: 100%; }
	.fieldset-wrapper{
		> .form-item{position: relative;

			label{width: 60%; display: inline-block!important; vertical-align: top; margin: 0;}
			label+*{width: 38%; display: inline-block; vertical-align: top;}
		    input.form-text {padding: 5px 40px 5px 10px;  }
		    .field-suffix{position: absolute; width: 30px; border-left: 1px solid #d9d9d9; line-height: 36px; text-align: center; color: #999; top: 0px; right: 13px;}

		    .form-type-radio{
		    	label{width: auto; margin-right: 15px;}
		    }

		    .description{display: none; position: absolute; padding: 10px; background: #FFF; }

		    // #edit-submitted-votre-bien-motivation{ -webkit-appearance: listbox; height: 130px}

		    #edit-submitted-vos-coordonees-commentaires{height: 130px;}
		    .form-checkboxes{

		    	.form-item{width: 100%; display: block;
		    		label{width: 90%;}
		    	}
		    }
		}
	}
}

// @for $i from 1 through 6 {
//    #block-system-main article:nth-child(#{$i}) {
//        background: darken($or, 5% * $i);
//    }
// }

/* -----------------------------------------
   WEBFORM SIMULATEUR de crédit
----------------------------------------- */
#block-webform-client-block-78411 { max-width: 850px; margin: auto;

	.titre-simulateur {
		background: #018c92;
		color: #fff;
		text-transform: uppercase;
		padding: 10px 5px;
		text-align: center;
		width: 100%;
	}

	.webform-client-form { padding: 10px; margin: 0 2px; border: 1px solid #eaeaea;

		div{
			>.form-item{ position: relative;
				label{ width: 60%;display: inline-block !important; vertical-align: top; margin: 0; }
				label+*{ width: 38%; display: inline-block; vertical-align: top; }
				input.form-text {padding: 5px 40px 5px 10px;  }
				.field-suffix {
					position: absolute;
					width: 30px;
					border-left: 1px solid #d9d9d9;
					line-height: 36px;
					text-align: center;
					color: #999;
					top: 0;
					right: 13px;
				}
			}
			.webform-component--frais-de-notaire,
			.webform-component--montant-de-lemprunt{display: none;}
			#edit-submitted-mensualite{text-align: right;}
		}

	}

}
/* ==========================================================================
|  RESPONSIVE
========================================================================== */

@import "responsive";
// @import "responsive_md";
// @import "responsive_sm";
// @import "responsive_xs";
