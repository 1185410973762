$couleur_base: #018c92;
$couleur_base_opacity: rgba(1, 140, 146, 0.8);
$couleur_base_opacity2: rgba(1, 140, 146, 0.1);
$bleu: #018c92;
$orange: #e4741e;
$marron: #5e3327;
$gris: #333333;
$bg_header: 'url(../images/bg_header2.png) no-repeat right';
$picto_contact: url(../images/picto_contact.jpg) no-repeat center #FFF;
$arrow_left: 'url(../images/arrow_left.png) no-repeat 0 0!important';
$arrow_right: 'url(../images/arrow_right.png) no-repeat 0 0!important';
$bloc_accueil: 'url(../images/pour_habiter_bg.jpg) no-repeat';@charset "utf-8";


/* ==========================================================================
|  VARIABLES
========================================================================== */
$couleur_base : #018c92;

$couleur_base_opacity : rgba(1, 140, 146, 0.8);
$couleur_base_opacity2 : rgba(1, 140, 146, 0.1);
$bleu : #018c92;
$orange : #e4741e;
$marron : #5e3327;
$gris : #333333;
$orange2 : #d88748;

$bg_header : url(../images/bg_header2.png) no-repeat right;
$picto_contact : url(../images/picto_contact.jpg) no-repeat center #FFF;

$arrow_left : url(../images/arrow_left.png) no-repeat 0 0!important;
$arrow_right : url(../images/arrow_right.png) no-repeat 0 0!important;

$bloc_accueil : url(../images/pour_habiter_bg.jpg) no-repeat;

@import "lodgim";

